
import { defineComponent, nextTick, reactive, watch } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: ".png",
    },
    uniqueId: {
      type: String,
      default: "",
      required: true,
    },
    fileUrl: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const inputValue = reactive({
      file: null as File | Blob | null,
      fileName: "",
      fileSize: 0,
      fileUrl: "",
    });

    const handleDrag = (event: DragEvent) => {
      const files = event.dataTransfer?.files || [];
      setFile(files[0]);
    };

    const handleClick = (event: any) => {
      const inputEl = event.target as HTMLInputElement;
      const files = inputEl.files || [];
      setFile(files[0]);
      inputEl.value = "";
    };

    const triggerClick = () => {
      const id = `fileImage${props.uniqueId}`;
      const inputFileEl: any = document.getElementById(id);
      inputFileEl.click();
    };

    const setFile = async (file: File) => {
      if (!file) {
        return false;
      }

      inputValue.file = file;
      inputValue.fileName = file.name;
      inputValue.fileSize = file.size;

      const setupFile: any = file;

      emit("update:value", setupFile);
      return true;
    };

    const renderAsImage = async () => {
      const file = inputValue.file;
      if (!file) return;

      const imagePreviewId = `#imagePreview${props.uniqueId}`;

      const reader = new FileReader();
      reader.readAsDataURL(file as File);
      reader.onload = function (e: any) {
        cash(imagePreviewId).attr("src", e.target.result);
      };
    };

    watch(
      () => props.value,
      (newVal) => {
        if (newVal) renderAsImage();
      }
    );

    const renderFileUrl = async (fileUrl: string) => {
      await nextTick();

      // setup name file
      const reverseFileName = fileUrl.split("").reverse();
      const tempName = [] as string[];

      for (let i = 0; i < reverseFileName.length; i++) {
        if (reverseFileName[i] === "/") break;
        tempName.push(reverseFileName[i]);
      }

      // update fileName
      inputValue.fileName = tempName.reverse().join("");

      // show existing image
      const imagePreviewId = `#imagePreview${props.uniqueId}`;
      cash(imagePreviewId).attr("src", fileUrl);
    };

    watch(
      () => props.fileUrl,
      (newVal) => {
        renderFileUrl(newVal);
      }
    );

    const removeImage = () => {
      inputValue.file = null;
      inputValue.fileName = "";
      inputValue.fileSize = 0;

      emit("update:value", "");
    };

    return {
      inputValue,
      handleDrag,
      handleClick,
      triggerClick,
      removeImage,
    };
  },
});
